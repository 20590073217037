<template>
  <ion-page>
    <GlobalToolbar :page="'notifications'" />

    <ion-content class="ion-padding">
      <ion-row class="ion-align-items-center" :style="'height:100%;background:#F8F8F8'">
        <ion-col size="12" style="padding:5vh 12vw;text-align:center;">
          <span style="font-family:'Satisfy', cursive !important;font-weight:400;font-size:25px;color:#ACACAC;">Stay updated with notifications here</span>
          <SolidButton :text="'ADD FRIENDS'" :color="'#00777E'" :style="'margin:5vh 0 0;'" @click="addAFriend" v-if="user.profile.role==='consumer'" />
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed } from 'vue';

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol } from '@ionic/vue'
import { modalController  } from '@ionic/vue'; 

/* Components */
import GlobalToolbar from '@/components/Toolbar'
import SolidButton from '@/components/buttons/Solid'

export default {
  name: 'notifications',

  components: {
    IonContent, IonPage, IonRow, IonCol,
    GlobalToolbar, SolidButton
  },

  setup() {
    const { state } = useStore();
    const user = computed(() => state.auth.user);

    const addAFriend = async () => {
      const modal = await modalController
        .create({
          component: require('@/components/modals/Friends').default,
        })
      return modal.present();
    };

    return {
      user,
      addAFriend
    }
  }

}
</script>
